import React from "react";
import { endsWith } from "lodash";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/styles";
import authentication from "../../auth/react-azure-adb2c";
import DefaultButton from "./items/default-button";
import { SendClickedAppDownloadEvent, SendSuccessfullyAuthenticatedEvent } from "../helpers/amplitude-analytics";
import hcfStaffConsentChange from "../actions/hcf-staff-consent"
import { getRegisterLoginState } from "../reducers/user";

const useStyles = makeStyles(() => ({
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  appLogos: {
    maxWidth: "214px",
    padding: 10,
    display: "block",
  },
  checkboxLabel: {
    marginBottom: 0,
  },
  checkboxMargin: {
    marginBottom: 20,
  },
  healthyHabits: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
  },
  healthyHabitsBlock: {
    fontSize: 16,
    margin: "28px auto 0",
  },
  termsAndConditions: {
    lineHeight: "20px",
    maxWidth: 470,
    fontSize: 14,
  }
}));

const signOut = () => authentication.signOut();

const DownloadAppPage = (props) => {
  const isHcfStaff = props.isHcfStaffCode && props.registerLogin.success;
  const classes = useStyles();
  SendSuccessfullyAuthenticatedEvent();
  const policyId = authentication.signedIn()?.idTokenClaims?.policyId;
  const isNewSignup = endsWith(policyId, "signup");
  const [consented, setConsented] = React.useState(props.registerLogin.consented);
  const [disabled, setDisabled] = React.useState(false);
  const [seeMore, setSeeMore] = React.useState(false);
  const { hcfStaffConsentChange: hcfStaffConsentChangeAction } = props;

  const consentChange = () => {
    setDisabled(true);
    hcfStaffConsentChangeAction(!consented).then(() => setDisabled(false));
    setConsented(!consented);
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      className="mb-8"
      style={{textAlign: "center", paddingLeft: 10, paddingRight: 10}}
    >
      <Grid item xs={12} lg={4} style={{ margin: 16 }}>
        <CheckCircleIcon color="secondary" style={{ fontSize: 60 }} className="mb-8" />

        {isNewSignup ? (
          <>
            <Typography style={{ fontSize: 24, marginBottom: 40 }}>
              Your Snug account was successfully created!
            </Typography>
          </>
        ) : (
          <>
            <Typography style={{ fontSize: 24, marginBottom: 8 }}>
              Your Snug account was successfully linked with {process.env.REACT_APP_WEBSITE_PARTNER_NAME}!
            </Typography>
            <Typography style={{ fontSize: 14, marginBottom: 10, lineHeight: 1.5 }}>
              If you are already logged into Snug on your device, you will need to logout and log back in to finalise linking your account.
            </Typography>
          </>
        )}

        <div className={classes.logoContainer}>
          <a className={classes.appLogos} href={process.env.REACT_APP_IOS_APP_STORE_LINK} onClick={() => SendClickedAppDownloadEvent("iOS")} target="_app">
            <img src="./assets/images/AppStore.png" alt="View in App Store" />
          </a>
          <a className={classes.appLogos} href={process.env.REACT_APP_GOOGLE_PLAY_STORE_LINK} onClick={() => SendClickedAppDownloadEvent("GooglePlay")} target="_app">
            <img src="./assets/images/GooglePlay.png" alt="View in Google Play" />
          </a>
        </div>
        <div className={ isHcfStaff ? "registerAnotherWide" : ""}>
          <Typography style={{ fontSize: 18, marginTop: 32 }}>If you wish to register another member please sign out and start this process again</Typography>
          <DefaultButton onClick={() => signOut()} style={{ textTransform: "none" }} label="Sign out" />
        </div>
      </Grid>

      {isHcfStaff &&
        <React.Fragment>
          <Grid item xs={12} lg={4} className={`healthyHabits ${classes.healthyHabits}`} style={{ maxWidth: 640 }}>
            <img src="./assets/images/HCF/healthyHabitsLargeGrey.png" width="241" height="85" alt="Healthy Habits" />
            <Typography className={classes.healthyHabitsBlock}>Snug Health is proud to partner with HCF to support the Healthy Habits Challenge for all HCF employees.</Typography>
            <Typography onClick={() => setSeeMore(!seeMore)} className={classes.healthyHabitsBlock} style={{ cursor: "pointer", maxWidth: 200 }}>
              { seeMore && <div style={{ lineHeight: "15px" }}>See Less<br /><KeyboardArrowUpIcon /></div> }
              { !seeMore && <div style={{ lineHeight: "15px" }}>See More<br /><KeyboardArrowDownIcon /></div> }
            </Typography>
            {seeMore &&
              <React.Fragment>
                <Typography className={classes.healthyHabitsBlock} style={{ marginTop: 10 }}>
                  You will earn Healthy Hearts by engaging in the Snug App to meet your activity-based goals.
                  As you earn Healthy Hearts you will progress through to new levels and go into the draw to win greater rewards.
                </Typography>
                <Typography className={classes.healthyHabitsBlock} style={{ marginTop: 10 }}>
                  To find out more about how to earn Healthy Hearts and the rewards available, refer to the <a href="https://hcfofaustralia.sharepoint.com/sites/HCF-Health-Wellbeing/SitePages/HEALTHY-HABITS-CHALLENGE.aspx">Healthy Habits SharePoint page</a> or email. If you have any questions, please email <a href="mailto:wellbeing@hcf.com.au">wellbeing@hcf.com.au</a>
                </Typography>
              </React.Fragment>
            }
            <React.Fragment>
              <Typography className={classes.healthyHabitsBlock}>
                Do you give Snug Health consent to disclose to the HCF Health and Wellbeing Team your name and email address,
                the number of goals you have achieved each week and whether you have completed the Snug Check-in within the app?
              </Typography>
              <Typography className={classes.healthyHabitsBlock}>
                {!consented && <span>You have not consented to share this information.</span>}
                {consented && <span>You have consented to share this information.</span>}
                <DefaultButton
                  onClick={() => consentChange()}
                  disabled={disabled}
                  style={{ textTransform: "none", marginTop: 10 }}
                  label={consented ? "Remove Consent" : "Grant Consent" }
                />
              </Typography>
              <Typography
                className={ `${classes.termsAndConditions} ${classes.healthyHabitsBlock} ${classes.abc}` }
                style={{ fontSize: 14 }}>
                Snug Health will not disclose specific health activity data or health information to the HCF Health and Wellbeing Team,
                but just your name, email address and whether or not you have completed the goal/s for the purpose of the staff competition.
              </Typography>
              <Typography
                className={ `${classes.termsAndConditions} ${classes.healthyHabitsBlock}` }
                style={{ fontSize: 14 }}>
                If you choose not to give Snug Health this consent, then unfortunately you won’t be able to take part in the staff competition.
                How HCF collects, uses, discloses, keeps and secures personal information is explained in the HCF privacy policy: <a href="https://hcf.com.au/privacy" target="_blank" rel="noreferrer">hcf.com.au/privacy</a>
              </Typography>
              <img src="./assets/images/HCF/healthyHabitsFooter.png" width="308" height="61" alt="Footer" style={{ paddingTop: 20 }} />
            </React.Fragment>
          </Grid>
          <Grid item xs={10} className="registerAnotherNarrow">
            <Typography style={{ fontSize: 18, marginTop: 32, marginLeft: "auto", marginRight: "auto", maxWidth: 500 }}>
              If you wish to register another member please sign out and start this process again
            </Typography>
            <DefaultButton onClick={() => signOut()} style={{ textTransform: "none" }} label="Sign out" />
          </Grid>
        </React.Fragment>
      }
    </Grid>
  );
};

export default (connect((state) => ({
  registerLogin: getRegisterLoginState(state),

}),
{ hcfStaffConsentChange })(DownloadAppPage));
